import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de poca altura que canvia de coloració de marró obscur a marró grisenc i crema depenent del seu grau d’hidratació. El capell fa fins a 4 cm de diàmetre convex i llavors més o manco pla amb el centre molt umbilicat. El marge és prim i ondulat, més o manco lobulat. Les làmines de davall estan més aviat separades de color gris ocraci a marró grisenc. El peu és de cilíndric a un poc aplanat de color marró clar a obscur depenent també de la hidratació que tingui. Les espores són blanques en massa, el·lipsoïdals i llises, de 6-8 x 3,5-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      